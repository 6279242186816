import { Stack, Box } from "@mui/system";
import React, { useState } from "react";
import TutoCardItem from "./TutoCardItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { styled, useTheme } from "@mui/material/styles";

import "swiper/css";

import SwiperType from "swiper";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 32,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#3D9ACC",
  },
}));

const TutoCardsMobDevice: React.FC = () => {
  const [pagination, setPagination] = useState<number>(50);
  const { t: trans } = useTranslation();

  return (
    <>
      <Swiper
        onActiveIndexChange={(swiper: SwiperType) => {
          const value = ((swiper.activeIndex + 2) * 100) / 4;
          setPagination(value);
        }}
        slidesPerView={2}
        spaceBetween={20}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        }}
      >
        <SwiperSlide>
          <TutoCardItem
            img="/images/tuto1.svg"
            text={trans("sarah.reason.1")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <TutoCardItem
            img="/images/tuto2.svg"
            text={trans("sarah.reason.2")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <TutoCardItem
            img="/images/tuto3.svg"
            text={trans("sarah.reason.3")}
          />
        </SwiperSlide>
        <SwiperSlide>
          <TutoCardItem
            img="/images/tuto4.svg"
            text={trans("sarah.reason.4")}
          />
        </SwiperSlide>
      </Swiper>
      <Box margin="auto" marginTop={4} width="50%">
        <BorderLinearProgress
          color="primary"
          variant="determinate"
          value={pagination}
        />
      </Box>
    </>
  );
};

const TutoCardsDesktopDevice: React.FC = () => {
  const { t: trans } = useTranslation();

  return (
    <Stack direction="row" spacing={2}>
      <TutoCardItem img="/images/tuto1.svg" text={trans("sarah.reason.1")} />
      <TutoCardItem img="/images/tuto2.svg" text={trans("sarah.reason.2")} />
      <TutoCardItem img="/images/tuto3.svg" text={trans("sarah.reason.3")} />
      <TutoCardItem img="/images/tuto4.svg" text={trans("sarah.reason.4")} />
    </Stack>
  );
};

const TutoCards: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  return matches ? <TutoCardsMobDevice /> : <TutoCardsDesktopDevice />;
};

export default TutoCards;
