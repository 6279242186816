import React, { PropsWithChildren, useContext } from "react";
import { Container, Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import DecathlonFootScan from "../../components/DecathlonFootScan";
import { useTranslation } from "react-i18next";
import { ReactComponent as WellDoneImg } from "../../assets/bravo.svg";
import { ReactComponent as WellDoneXsImg } from "../../assets/bravo-xs.svg";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import ToSsProfileButton from "../../components/button/ToSsProfileButton";
import { Await, useLoaderData } from "react-router-dom";
import ToMemberProfileButton from "../../components/button/ToMemberProfileButton";
import HomeSkeletonPage from "../SkeletonPage";

const DataContext = React.createContext<string>("");

const ThemeContext = React.createContext<boolean>(false);

const Layout: React.FC<PropsWithChildren> = ({ children }) => (
  <Container maxWidth="xl">
    <DecathlonFootScan />
    {children}
  </Container>
);

const ButtonGroup: React.FC = () => {
  const url = useContext(DataContext);
  const theme = useTheme();
  const isMobile = useContext(ThemeContext);
  const { t: trans } = useTranslation();

  return (
    <>
      <ToMemberProfileButton
        sx={{
          [theme.breakpoints.up("md")]: {
            marginY: 2,
          },
        }}
        fullWidth={isMobile}
      >
        {trans("go.to.account")}
      </ToMemberProfileButton>
      <ToSsProfileButton variant="contained" url={url} fullWidth={isMobile}>
        {trans("Voir le 3D de mon pied")}
      </ToSsProfileButton>
    </>
  );
};

const Aside: React.FC = () => {
  const theme = useTheme();
  const isMobile = useContext(ThemeContext);
  const { t: trans } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" flexBasis={350}>
      <Typography
        data-testid="success"
        variant="h4"
        fontSize={32}
        fontWeight={700}
      >
        Bravo !
      </Typography>
      <Typography
        variant="body1"
        fontSize={20}
        fontWeight={700}
        paddingTop={2}
        sx={{ [theme.breakpoints.down("md")]: { fontSize: 28 } }}
      >
        {trans("data.sended")}
      </Typography>
      {isMobile && (
        <Box marginTop={6}>
          <WellDoneXsImg />
        </Box>
      )}
      {!isMobile && <ButtonGroup />}
    </Box>
  );
};

const ResponsiveContent: React.FC = () => {
  const isMobile = useContext(ThemeContext);

  return (
    <>
      <Aside />
      {!isMobile && <WellDoneImg width={420} height={420} />}
      {isMobile && (
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="center"
          spacing={3}
          marginY={3}
        >
          <ButtonGroup />
        </Stack>
      )}
    </>
  );
};

const ContentPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Layout>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-evenly"
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "normal",
          },
        }}
      >
        <ThemeContext.Provider value={isMobile}>
          <ResponsiveContent />
        </ThemeContext.Provider>
      </Box>
    </Layout>
  );
};

const AsyncContent: React.FC = () => {
  const safesize = useLoaderData() as { url: string };

  return (
    <Await resolve={safesize.url}>
      {(url) => (
        <DataContext.Provider value={url}>
          <ContentPage />
        </DataContext.Provider>
      )}
    </Await>
  );
};

const HomePage: React.FC = () => {
  return (
    <React.Suspense fallback={<HomeSkeletonPage />}>
      <AsyncContent />
    </React.Suspense>
  );
};

export default HomePage;
