import React, { PropsWithChildren, useContext } from "react";
import { Container, Box, Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import ToDecathLoginButton from "../../components/button/ToDecathLoginButton";
import ToSsProfileButton from "../../components/button/ToSsProfileButton";
import DecathlonFootScan from "../../components/DecathlonFootScan";
import TutoCards from "../../components/cards/TutoCards";
import { Trans } from "react-i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import { Await, useLoaderData } from "react-router-dom";
import { SafesizeDataType } from "../../services/safesizeApi";
import ConnexionSkeletonPage from "../SkeletonPage";

const SafesizeDataContext = React.createContext<SafesizeDataType | undefined>(
  undefined
);

const ThemeContext = React.createContext<boolean>(false);

const Layout: React.FC<PropsWithChildren> = ({ children }) => (
  <Container maxWidth="xl">
    <DecathlonFootScan />
    {children}
  </Container>
);

const ButtonGroup: React.FC = () => {
  const data = useContext(SafesizeDataContext);
  const isMobile = useContext(ThemeContext);

  return (
    <>
      <ToSsProfileButton url={data?.foot_profile_url} fullWidth={isMobile}>
        <Trans>no.button</Trans>
      </ToSsProfileButton>
      <ToDecathLoginButton fullWidth={isMobile}>
        <Trans>yes.button</Trans>
      </ToDecathLoginButton>
    </>
  );
};

const Aside: React.FC = () => {
  const isMobile = useContext(ThemeContext);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-around"
    >
      <Typography variant="h2" fontSize={40} fontWeight={700}>
        <Trans>send.decath.account</Trans>
      </Typography>
      {!isMobile && <ButtonGroup />}
    </Box>
  );
};

const TutoSide: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      marginLeft={5}
      sx={{
        [theme.breakpoints.down("lg")]: {
          marginLeft: 0,
          marginY: 4,
        },
      }}
    >
      <Typography variant="h6" fontSize={18} fontWeight={400} paddingBottom={1}>
        <Trans>sarah.reason</Trans>
      </Typography>
      <Box>
        <TutoCards />
      </Box>
    </Box>
  );
};

const ResponsiveContent: React.FC = () => {
  const isMobile = useContext(ThemeContext);

  return (
    <>
      <Aside />
      <TutoSide />
      {isMobile && (
        <Stack
          display="flex"
          flexDirection="column"
          alignItems="center"
          spacing={2}
          marginBottom={3}
        >
          <ButtonGroup />
        </Stack>
      )}
    </>
  );
};

const ContentPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Layout>
      <Box
        display="flex"
        sx={{
          [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
          },
        }}
      >
        <ThemeContext.Provider value={isMobile}>
          <ResponsiveContent />
        </ThemeContext.Provider>
      </Box>
    </Layout>
  );
};

const AsyncContent: React.FC = () => {
  const data = useLoaderData() as { safesizeData: SafesizeDataType };

  return (
    <Await resolve={data.safesizeData}>
      {(data) => (
        <SafesizeDataContext.Provider value={data}>
          <ContentPage />
        </SafesizeDataContext.Provider>
      )}
    </Await>
  );
};

const ConnexionPage: React.FC = () => {
  return (
    <React.Suspense fallback={<ConnexionSkeletonPage />}>
      <AsyncContent />
    </React.Suspense>
  );
};

export default ConnexionPage;
