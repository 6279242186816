import React from "react";
import { Container, Box } from "@mui/system";
import DecathlonFootScan from "../components/DecathlonFootScan";
import LinearProgress from "@mui/material/LinearProgress";

const SkeletonPage: React.FC = () => (
  <>
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
    <Container maxWidth="xl">
      <DecathlonFootScan />
    </Container>
  </>
);

export default SkeletonPage;
