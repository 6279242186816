import React from "react";
import { Container, Box } from "@mui/system";
import Typography from "@mui/material/Typography";
import DecathlonFootScan from "../../components/DecathlonFootScan";
import { useTranslation } from "react-i18next";
import CustomButton from "../../components/button/CustomButton";
import { ReactComponent as ErrorImg } from "../../assets/error.svg";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

const ErrorPage: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { t: trans } = useTranslation();
  const navigate = useNavigate();

  function goBack() {
    const uuid = localStorage.getItem("uuid");

    if (uuid) {
      navigate(`/?id=${uuid}`);
    }
  }

  return (
    <Container maxWidth="lg">
      <DecathlonFootScan />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
          },
        }}
      >
        <Box display="flex" flexDirection="column" flexBasis={350}>
          <Typography variant="h4" fontSize={32} fontWeight={700}>
            Oops !
          </Typography>
          <Typography
            variant="body1"
            data-testid="sfError"
            fontSize={20}
            fontWeight={700}
            paddingY={4}
          >
            {trans("session.invalid.error")}
          </Typography>
          {!matches && (
            <CustomButton
              color="primary"
              variant="contained"
              size="large"
              onClick={goBack}
            >
              {trans("go.back")}
            </CustomButton>
          )}
          {matches && (
            <Box display="flex" justifyContent="center">
              <ErrorImg width={300} height={300} />
            </Box>
          )}
        </Box>
        {!matches && <ErrorImg />}
        {matches && (
          <CustomButton
            color="primary"
            variant="contained"
            size="large"
            fullWidth
            sx={{ marginTop: 10 }}
            onClick={goBack}
          >
            {trans("go.back")}
          </CustomButton>
        )}
      </Box>
    </Container>
  );
};

export default ErrorPage;
