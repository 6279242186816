const en = {
  "send.decath.account":
    "Would you also like send the data scanning on your Decathlon account?",
  "session.invalid.error":
    "We were unable to retrieve your foot data. Your session seems to be invalid",
  "go.back": "Go Back",
  "yes.button": "Yes!",
  "no.button": "No, show me just the result",
  "sarah.reason": "Here a reason why Sarah did it",
  "data.sended": "Your data has been successfully sent to your account!",
  "go.to.account": "Go to my account",
  "show.3d.button": "See my 3D foot",
  "sarah.reason.1":
    "Sarah is looking for a bike on Decathlon.fr. She is rather short and hesitates between S or M.",
  "sarah.reason.2":
    "She has her body measurements saved on her account.So easily!",
  "sarah.reason.3":
    "This allows to display the correct size of each product.For the bike: it's an M.",
  "sarah.reason.4":
    "By recording her measurements, Sarah saved time and order a perfect size fitting bike!",
};

export default en;
