import React from "react";
import { SxProps } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import CustomButton from "./CustomButton";

const ToSsProfileButton: React.FC<
  ButtonProps & {
    url: string | undefined;
    sx?: SxProps;
  }
> = (props) => {
  function open3DsfProfile() {
    window.open(props.url, "_blank", "noopener,noreferrer");
  }

  return (
    <CustomButton
      data-testid="3DsfProfile"
      onClick={open3DsfProfile}
      color="primary"
      variant={props.variant || "text"}
      size="large"
      {...props}
    >
      {props.children}
    </CustomButton>
  );
};

export default ToSsProfileButton;
