import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const TutoCardItem: React.FC<{
  img: string;
  text: string;
}> = ({ img, text }) => {
  const theme = useTheme();
  const { t: trans } = useTranslation();

  return (
    <Card
      sx={{
        height: 265,
        [theme.breakpoints.up("lg")]: {
          width: 200,
        },
      }}
    >
      <CardMedia
        component="img"
        image={img}
        sx={{ backgroundColor: "#E7F3F9" }}
      />
      <CardContent>
        <Typography variant="body2">{trans(text)}</Typography>
      </CardContent>
    </Card>
  );
};

export default TutoCardItem;
