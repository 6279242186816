export type FootInfoType = {
  length: number;
  width: number;
  height: number;
};

export type SafesizeDataType = {
  foot_profile_url: string;
  foot: {
    left: FootInfoType;
    right: FootInfoType;
  };
};

export async function getSafesizeData(uuid: string): Promise<SafesizeDataType> {
  const response = await fetch(`${API_URL}/safesize?id=${uuid}`, {
    headers: {
      "x-api-key": API_KEY,
    },
  });

  if (!response.ok) {
    throw new Error();
  }

  return response.json();
}
