const fr = {
  "send.decath.account":
    "Souhaitez-vous également envoyer les données du scan sur votre compte Decathlon?",
  "session.invalid.error":
    "Nous n'avons pas pu récupérer vos données du pied. Votre session semble être invalide",
  "go.back": "Retour",
  "yes.button": "Oui!",
  "no.button": "Non, afficher juste les résultats",
  "sarah.reason": "Voici des raisons pour lesquelles Sarah l'a fait",
  "data.sended": "Vos données ont bien été envoyées sur votre compte!",
  "go.to.account": "Accéder à mon compte",
  "show.3d.button": "Voir le 3D de mon pied",
  "sarah.reason.1":
    "Sarah recherche un vélo sur Decathlon.fr. Elle est plutôt petite et hésite entre S ou M.",
  "sarah.reason.2":
    "Ses mensurations sont enregistrées sur son compte. Si facilement!",
  "sarah.reason.3":
    "Cela permet d'afficher la bonne taille de chaque produit.Pour le vélo : c'est un M.",
  "sarah.reason.4":
    "En prenant ses mesures, Sarah a gagné du temps et a commandé le vélo à la taille parfaite !",
};

export default fr;
