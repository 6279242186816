import { FootInfoType } from "./safesizeApi";

export function signinRedirect() {
  const url = `${AUTH0_DOMAIN}/connect/oauth/authorize?client_id=${AUTH0_CLIENTID}&redirect_uri=${AUTH0_REDIRECTURI}&response_type=code`;
  document.location = url;
}

export function redirectToAccount() {
  window.location.href = `${DECATHACCOUNT}`;
}

export async function postDataMember(
  foot: {
    left: FootInfoType;
    right: FootInfoType;
  },
  code: string
): Promise<string> {
  const response = await fetch(`${API_URL}/member?code=${code}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-api-key": API_KEY,
    },
    body: JSON.stringify({
      ...foot,
    }),
  });

  if (!response.ok) {
    throw new Error();
  }

  return response.json();
}
