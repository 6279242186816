import { useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { ReactComponent as DecathFootScan } from "../assets/logo.svg";

const DecathlonFootScan: React.FC = () => {
  const theme = useTheme();

  return (
    <Box
      marginY={3}
      sx={{
        [theme.breakpoints.down("lg")]: {
          textAlign: "center",
        },
      }}
    >
      <DecathFootScan />
    </Box>
  );
};

export default DecathlonFootScan;
