import { LoaderFunctionArgs, defer } from "react-router-dom";
import { getSafesizeData } from "./safesizeApi";
import { postDataMember } from "./memberBodyApi";

export async function useGetSafesizeDataLoader({
  request,
}: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const uuid = url.searchParams.get("id");

  if (!uuid) {
    throw new Error("Error while getting session id");
  }

  localStorage.setItem("uuid", uuid);

  return defer({ safesizeData: getSafesizeData(uuid) });
}

async function postDataRequest(uuid: string, code: string): Promise<string> {
  try {
    const data = await getSafesizeData(uuid);
    await postDataMember(data.foot, code);

    return data.foot_profile_url;
  } catch {
    throw new Error("Error while getting data feet information");
  }
}

export async function usePostDataMemberLoader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const code = url.searchParams.get("code");
  const uuid = localStorage.getItem("uuid");

  if (!uuid || !code) {
    throw new Error("Error while getting session id");
  }

  return defer({ url: postDataRequest(uuid, code) });
}
