import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/system";

const CustomButton = styled(Button)<ButtonProps>(({ fullWidth }) => ({
  textTransform: "none",
  fontWeight: 700,
  width: fullWidth ? "100%" : 250,
}));

export default CustomButton;
