import { SxProps } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import React from "react";
import { redirectToAccount } from "../../services/memberBodyApi";
import CustomButton from "./CustomButton";

const ToMemberProfileButton: React.FC<ButtonProps & { sx?: SxProps }> = (
  props
) => (
  <CustomButton
    data-testid="memberProfile"
    onClick={redirectToAccount}
    color="primary"
    variant="text"
    size="large"
    fullWidth
    {...props}
  >
    {props.children}
  </CustomButton>
);

export default ToMemberProfileButton;
