import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./pages/error/ErrorPage";
import HomePage from "./pages/home/HomePage";
import { createTheme, CssBaseline, Theme, ThemeProvider } from "@mui/material";
import ConnexionPage from "./pages/connexion/ConnexionPage";
import {
  useGetSafesizeDataLoader,
  usePostDataMemberLoader,
} from "./services/dataLoader";

const router = createBrowserRouter([
  {
    path: "/",
    element: <ConnexionPage />,
    loader: useGetSafesizeDataLoader,
    errorElement: <ErrorPage />,
  },
  {
    path: "/home",
    element: <HomePage />,
    loader: usePostDataMemberLoader,
    errorElement: <ErrorPage />,
  },
]);

const theme: Theme = createTheme({
  palette: {
    primary: {
      light: "#3386b0",
      main: "#00689D",
      dark: "#00486d",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: '"Roboto"',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          background: linear-gradient(102.71deg, #E7F3F9 0%, #FFFFFF 100%);
        }
      `,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
