import { ButtonProps } from "@mui/material/Button";
import { SxProps } from "@mui/material";
import React from "react";
import { signinRedirect } from "../../services/memberBodyApi";
import CustomButton from "./CustomButton";

const ToDecathLoginButton: React.FC<ButtonProps & { sx?: SxProps }> = (
  props
) => {
  return (
    <CustomButton
      data-testid="decathLogin"
      onClick={signinRedirect}
      color="primary"
      variant="contained"
      size="large"
      {...props}
    >
      {props.children}
    </CustomButton>
  );
};

export default ToDecathLoginButton;
